import React, { Fragment } from 'react';

import { AuthCompanyContext, useUserServices } from '@homesusa/auth';
import { FormContext } from '@homesusa/form';
import { getInitialPhotoInformation } from '@homesusa/photo-request';
import { SelectCompany as CoreSelectCompany } from 'core/components';
import { PhotoFormContextProps } from '../../contexts';
import { Dialog, DialogContent } from '@mui/material';

export function SelectCompany(): JSX.Element {
  const {
    otherProps: { getCompanyDetails },
    formState: { type, property },
    formDispatch
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [photographyServicesEnabled, setPhotographyServicesEnabled] = React.useState<boolean>(true);
  const { getUserDetails } = useUserServices();

  const getAndSetCompany = async (companyId?: string): Promise<void> => {
    const company = !!companyId ? await getCompanyDetails(companyId) : undefined;

    if (company) {
      setPhotographyServicesEnabled(company.photographyServiceInfo?.photographyServicesEnabled);
      setShowModal(!company.photographyServiceInfo?.photographyServicesEnabled);
    }

    const user = await getUserDetails();
    const photoInfo = !!company ? getInitialPhotoInformation({ user, company }) : {};

    if (formDispatch) {
      formDispatch({
        type: 'OverrideState',
        payload: Object.assign({
          ...photoInfo,
          type,
          property: {
            type: property?.type
          }
        })
      });
    }
  };

  React.useEffect(() => {
    if (currentCompany) {
      getAndSetCompany(currentCompany.id);
    }
  }, [currentCompany, showModal, photographyServicesEnabled]);

  if (currentCompany) {
    return <Fragment></Fragment>;
  }

  return (
    <>
      <CoreSelectCompany
        onSelect={(company): void => {
          getAndSetCompany(company?.id);
        }}
      />
      {!photographyServicesEnabled && (
        <Fragment>
          <Dialog open={showModal} onClose={(): void => setShowModal(false)}>
            <DialogContent>
              Photography service is currently disabled. To activate, please contact the MLS
              Department.
            </DialogContent>
          </Dialog>
        </Fragment>
      )}
    </>
  );
}
