import { ServiceType } from '@homesusa/photo-request';

export const hasVirtualStaging = ({ serviceOptions }: { serviceOptions?: string[] }): boolean => {
  const virtualTypes = [
    ServiceType.VirtualStagingSix,
    ServiceType.VirtualStagingThree,
    ServiceType.VirtualStagingOne
  ];
  return (
    !!serviceOptions &&
    serviceOptions.some((option) => virtualTypes.includes(option as ServiceType))
  );
};

export const getVirtualStagingNumber = (serviceOptions?: string[]): number | null => {
  if (serviceOptions) {
    if (serviceOptions.some((option) => option == ServiceType.VirtualStagingSix)) {
      return 6;
    }
    if (serviceOptions.some((option) => option == ServiceType.VirtualStagingThree)) {
      return 3;
    }
    if (serviceOptions.some((option) => option == ServiceType.VirtualStagingOne)) {
      return 1;
    }
  }
  return null;
};
