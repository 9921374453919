export enum ReportTypes {
  Residential = 'residential',
  Community = 'community',
  Plan = 'plan',
  Other = 'other'
}

export enum ReportColumnNames {
  Address = 'address',
  Subdivision = 'subdivision',
  PlanName = 'planName',
  BuilderName = 'builderName',
  MlsNumber = 'mlsNumber',
  CompletedOn = 'completedOn',
  StillsHome = 'stillsHome',
  StillsExterior = 'stillsExterior',
  VirtualTour = 'virtualTour',
  Twilight = 'twilight',
  VirtualStagingOneImage = 'virtualStagingOneImage',
  VirtualStagingThreeImage = 'virtualStagingThreeImage',
  VirtualStagingSixImage = 'virtualStagingSixImage',
  CommunityOption = 'CommunityOption',
  CommunityOption2 = 'CommunityOption2',
  CommunityOption3 = 'CommunityOption3',
  TripCharge = 'tripCharge',
  Total = 'total',
  Market = 'market',
  Order = 'order',
  PhotoshopEditing = 'photoshopEditing'
}

const ResidentialAndOtherColumns = [
  ReportColumnNames.MlsNumber,
  ReportColumnNames.Address,
  ReportColumnNames.BuilderName,
  ReportColumnNames.Subdivision,
  ReportColumnNames.Market,
  ReportColumnNames.CompletedOn,
  ReportColumnNames.Order,
  ReportColumnNames.StillsHome,
  ReportColumnNames.StillsExterior,
  ReportColumnNames.VirtualTour,
  ReportColumnNames.TripCharge,
  ReportColumnNames.Twilight,
  ReportColumnNames.VirtualStagingOneImage,
  ReportColumnNames.VirtualStagingThreeImage,
  ReportColumnNames.VirtualStagingSixImage,
  ReportColumnNames.PhotoshopEditing,
  ReportColumnNames.Total
];
export const columnsByReportType = {
  [ReportTypes.Residential]: ResidentialAndOtherColumns,
  [ReportTypes.Other]: ResidentialAndOtherColumns,
  [ReportTypes.Community]: [
    ReportColumnNames.Address,
    ReportColumnNames.BuilderName,
    ReportColumnNames.Market,
    ReportColumnNames.Subdivision,
    ReportColumnNames.CompletedOn,
    ReportColumnNames.Order,
    ReportColumnNames.CommunityOption,
    ReportColumnNames.CommunityOption2,
    ReportColumnNames.CommunityOption3,
    ReportColumnNames.TripCharge,
    ReportColumnNames.Twilight,
    ReportColumnNames.Total
  ],

  [ReportTypes.Plan]: [
    ReportColumnNames.PlanName,
    ReportColumnNames.Address,
    ReportColumnNames.Subdivision,
    ReportColumnNames.CompletedOn,
    ReportColumnNames.TripCharge,
    ReportColumnNames.Twilight,
    ReportColumnNames.VirtualStagingOneImage,
    ReportColumnNames.VirtualStagingThreeImage,
    ReportColumnNames.VirtualStagingSixImage,
    ReportColumnNames.Total
  ]
};
