import React, { Fragment } from 'react';
import { Button, Box, Stack } from '@mui/material';

import { FormContext, Input } from '@homesusa/form';
import { BlankPropertyTypes, PhotoRequestRestriction, PropertyType } from '@homesusa/photo-request';
import { PhotoFormContextProps } from '../../contexts';
import { SelectCompany } from './select-company.component';
import { SelectType } from './select-type.component';
import { SearchProperties } from './search-properties.component';

export function SelectStepContent({ handleNext }: { handleNext: () => void }): JSX.Element {
  const {
    formState: { companyId, property },
    otherProps: { company, isCompleteHome }
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const handleNextChange = (): void => {
    if (isCompleteHome) {
      handleNext();
      return;
    }

    if (
      !company.settingInfo.allowPhotoRequestForIncompleteHomes &&
      property.type === PropertyType.Residential
    ) {
      setShowModal(true);
      return;
    }

    handleNext();
  };
  return (
    <>
      <Stack spacing={3}>
        <SelectCompany />
        {!!companyId && <SelectType />}
        {!!companyId && !!property?.type && (
          <Fragment>
            {property.type == PropertyType.BlankPlan && (
              <Input label="Plan Name" name="property.planName" />
            )}
            {!BlankPropertyTypes.includes(property.type) && <SearchProperties />}
            <Box sx={{ textAlign: 'center' }}>
              <Button
                onClick={handleNextChange}
                disabled={
                  (!BlankPropertyTypes.includes(property.type) && !property.id) ||
                  (property.type == PropertyType.BlankPlan && !property.planName)
                }
              >
                Next
              </Button>
            </Box>
          </Fragment>
        )}
      </Stack>
      {showModal && <PhotoRequestRestriction showModal={showModal} setShowModal={setShowModal} />}
    </>
  );
}
