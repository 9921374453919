import { AssessmentTwoTone, Group, PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';

import { appStateDefault, AppType, AppState } from '@homesusa/layout';

enum SidebarItemType {
  PhotoService = 'photoService',
  photographers = 'photographers',
  Reports = 'reports'
}

export const appState: AppState = {
  ...appStateDefault,
  currentApp: AppType.PhotoService,
  layout: {
    ...appStateDefault.layout,
    sidebar: {
      ...appStateDefault.layout.sidebar,
      selected: SidebarItemType.PhotoService,
      items: {
        [SidebarItemType.PhotoService]: {
          title: 'Photo Service',
          icon: PhotoCameraIcon,
          items: [{ title: 'Requests', endpoint: '/photo-requests' }]
        }
      }
    }
  }
};

export const mlsAdminSideBarItems = {
  [SidebarItemType.photographers]: {
    title: 'Photographers',
    icon: Group,
    items: [{ title: 'Manage Photographers', endpoint: '/photographers' }]
  },
  [SidebarItemType.Reports]: {
    title: 'Reports',
    icon: AssessmentTwoTone,
    items: [
      { title: 'Photography Billing', endpoint: '/photographer-billing-reports' },
      { title: 'Photographers Payroll', endpoint: '/photographer-payroll-reports' }
    ]
  }
};
