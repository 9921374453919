import React, { Fragment } from 'react';
import { TextField, IconButton } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { Modal } from './modal.component';
import { notifyPhotoRequestEdited } from 'core/services/photo-request.service';
import { PhotoRequestContext } from 'modules/photo-requests/contexts';

export function NotifyRequestEdits(): JSX.Element {
  const [message, setmessage] = React.useState('');
  const {
    photoRequest: { id }
  } = React.useContext(PhotoRequestContext);
  const [showModal, setShowModal] = React.useState(false);

  const onSubmit = async (): Promise<void> => {
    if (id) {
      await notifyPhotoRequestEdited(id, message);
    }
  };

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Notify requestor">
        <ForwardToInboxIcon />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Notify requestor"
        actionButton={{
          text: 'Submit',
          method: async (): Promise<void> => {
            await onSubmit();
            setShowModal(false);
          }
        }}
      >
        <TextField
          fullWidth
          label="Message to requestor (optional):"
          multiline
          variant="outlined"
          name="message"
          rows={4}
          sx={{
            mt: 1
          }}
          onChange={(event): void => {
            setmessage(event.target.value);
          }}
        />
      </Modal>
    </Fragment>
  );
}
