import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Stack,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@mui/material';

import { AppContext, AppType, SubmitButton } from '@homesusa/layout';
import {
  PartialMediaProvider,
  mediaStateReducer,
  Resources,
  MediaState,
  SelectMedia
} from '@homesusa/media';
import { getVirtualStagingNumber } from 'core/utils';
import { getAll as getAllMedia } from 'core/services/media.service';
import { createVirtualStaging } from 'core/services/photo-request.service';
import { PhotoRequestContext } from '../contexts';

export function CreateVirtualStaging(): JSX.Element {
  const navigate = useNavigate();
  const {
    photoRequest: { id, serviceOptions, property }
  } = React.useContext(PhotoRequestContext);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [virtualStagingId, setVirtualStagingId] = React.useState<string>();
  const [mediaState, mediaStateDispatch] = React.useReducer(mediaStateReducer, {
    images: {},
    virtualTours: {}
  } as MediaState);
  const {
    appState: { apps }
  } = React.useContext(AppContext);

  const photoCount: number | null = React.useMemo(
    () => getVirtualStagingNumber(serviceOptions),
    [serviceOptions]
  );

  const filesSelected: string[] = React.useMemo(
    () =>
      Object.values(mediaState.images)
        .filter((media) => media.checked)
        .map((media) => media.id),
    [mediaState.images]
  );

  if (!id) {
    return <Fragment></Fragment>;
  }

  const handleImport = async (): Promise<void> => {
    const virtualId = await createVirtualStaging(id, filesSelected);
    setVirtualStagingId(virtualId);
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = (): void => {
    setOpenSuccessModal(false);
    navigate(`/photo-requests/${virtualStagingId}`);
  };

  return (
    <Fragment>
      <Box sx={{ mt: 1, mb: 1 }}>
        <PartialMediaProvider
          props={{
            entityId: id,
            mediaState,
            mediaStateDispatch,
            service: {
              getAll: (): Promise<Resources> => getAllMedia(id)
            }
          }}
        >
          <p>Please select the {photoCount} photo(s) you want virtually staged</p>
          <SelectMedia />
        </PartialMediaProvider>
      </Box>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <SubmitButton
          onClick={handleImport}
          buttonProps={{
            disabled: photoCount != filesSelected.length
          }}
        >
          Approve Selected
        </SubmitButton>
        <Button
          onClick={(): void => {
            navigate('/photo-requests');
          }}
        >
          Cancel
        </Button>
      </Stack>
      {virtualStagingId && (
        <Dialog
          onClose={(_, reason): void => {
            if (reason != 'backdropClick') {
              onCloseSuccessModal();
            }
          }}
          open={openSuccessModal}
        >
          <DialogTitle>Virtual Staging</DialogTitle>
          <DialogContent>
            <p>
              A photo request to virtually stage {photoCount} photo(s) has <em>just</em> been{' '}
              <em>submitted</em> to SpecDeck.
            </p>
            <p>
              Would you like to open your listing NOW to set photo order, add photo descriptions and
              submit your photo changes to MLS.
            </p>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Button onClick={onCloseSuccessModal}>No</Button>
              <Button
                onClick={(): void => {
                  const url = `${apps[AppType.Quicklister]}/sale-listings/${property.id}#media`;
                  window.open(url, '_blank', 'noopener,noreferrer');
                  onCloseSuccessModal();
                }}
              >
                Yes
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
}
