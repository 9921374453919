import React from 'react';
import { Box, LinearProgress } from '@mui/material';

import { FormContext } from '@homesusa/form';
import { PhotoRequestForm } from '@homesusa/photo-request';
import { AnimatedContainer } from '@homesusa/core';
import { PhotoFormContextProps } from 'modules/photo-requests/contexts';

export function FormGeneralTab(): JSX.Element {
  const {
    otherProps: { company }
  } = React.useContext<PhotoFormContextProps>(FormContext);

  if (!company) return <LinearProgress />;

  return (
    <AnimatedContainer>
      <Box mt={3} />
      <PhotoRequestForm />
    </AnimatedContainer>
  );
}
