import React, { Fragment } from 'react';
import { ThumbUpOutlined } from '@mui/icons-material';

import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { useApproveTripCharge } from '../../hooks';

export function ApproveTripCharge(): JSX.Element {
  const sendTripCharge = useApproveTripCharge();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Approve Trip Charge">
        <ThumbUpOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Trip Charge"
        actionButton={{
          text: 'Yes',
          method: async (): Promise<void> => {
            await sendTripCharge();
            setShowModal(false);
          }
        }}
      >
        <p>Are you sure you want Approve Trip Charge to the Photo Request?</p>
      </Modal>
    </Fragment>
  );
}
