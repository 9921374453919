import React, { Fragment } from 'react';

import { FormContext, Select } from '@homesusa/form';
import { PhotoRequestSourceType } from 'core/utils';
import { PhotoFormContextProps } from '../../contexts';
import { SelectRequestType } from '../select-request-type.component';

export function SelectType(): JSX.Element {
  const {
    formDispatch,
    formState: { type, companyId }
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const optionSource = React.useMemo(() => PhotoRequestSourceType.get(type) ?? {}, [type]);

  return (
    <Fragment>
      <SelectRequestType
        onSelect={(requestType): void => {
          if (formDispatch) {
            formDispatch({
              type: 'FormChangeByInputName',
              inputName: 'type',
              payload: requestType
            });
            formDispatch({
              type: 'FormChangeByInputName',
              inputName: 'property',
              payload: undefined
            });
          }
        }}
      />
      {!!companyId && (
        <Select
          fullWidth
          label="Source"
          options={optionSource}
          onChange={(propertyType): void => {
            if (formDispatch) {
              formDispatch({
                type: 'FormChangeByInputName',
                inputName: 'property',
                payload: {
                  type: propertyType
                }
              });
            }
          }}
        />
      )}
    </Fragment>
  );
}
