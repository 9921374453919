export function getOrdinalSuffix(n: number): string {
  const remainder10 = n % 10;
  const remainder100 = n % 100;

  if (remainder10 === 1 && remainder100 !== 11) {
    return `${n}st`;
  } else if (remainder10 === 2 && remainder100 !== 12) {
    return `${n}nd`;
  } else if (remainder10 === 3 && remainder100 !== 13) {
    return `${n}rd`;
  } else {
    return `${n}th`;
  }
}
