import React from 'react';
import { Grid, Typography } from '@mui/material';

import { AnimatedContainer, SectionBox } from '@homesusa/core';
import { DatePicker, Input, PhoneInput } from '@homesusa/form';
import { PhotographerServiceSection } from './photographer-services-section.component';

export const PhotographerForm = (): JSX.Element => {
  return (
    <AnimatedContainer>
      <Typography mb={2} variant="h3" color="initial">
        Photographer Details
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <SectionBox title="General">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input name="photographer.firstName" />
              </Grid>
              <Grid item xs={6}>
                <Input name="photographer.lastName" />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput name="photographer.businessPhone" />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput name="photographer.mobilePhone" />
              </Grid>
              <Grid item xs={6}>
                <Input name="photographer.userName" />
              </Grid>
              <Grid item xs={6}>
                <DatePicker name="photographer.sysCreatedOn" />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
        <Grid item xs={12}>
          <PhotographerServiceSection />
        </Grid>
      </Grid>
    </AnimatedContainer>
  );
};
