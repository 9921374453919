import React from 'react';

import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from 'modules/photo-requests/contexts';
import { AppContext } from '@homesusa/layout';

export const useSendMessageToPhotographer = (): ((message: string) => Promise<void>) => {
  const { photoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const sendMessage = async (message: string): Promise<void> => {
    if (!photoRequest || message === '') {
      return;
    }

    await PhotoRequestService.sendMessageToPhotographer(photoRequest, message);
    addAlert({
      message: 'Message sent to the photographer',
      variant: 'success'
    });
  };

  return sendMessage;
};
