import React from 'react';
import { Autocomplete, Typography, TextField } from '@mui/material';

import { FormContext } from '@homesusa/form';
import { useUserServices } from '@homesusa/auth';
import { getInitialPhotoInformation, PhotoRequestType } from '@homesusa/photo-request';
import { useMounted } from '@homesusa/core';
import { GetProperty } from 'core/services/quicklister.service';
import { Property, PropertyResponse } from 'core/interfaces';
import { PhotoFormContextProps } from '../../contexts';
import { useCatchHttpError } from '@homesusa/layout';

export function SelectProperty({ properties }: { properties: PropertyResponse[] }): JSX.Element {
  const {
    formState: { type, property },
    formDispatch,
    otherProps: { setHasCompletedPhotoRequest, company, setIsCompleteHome }
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const catchError = useCatchHttpError();
  const { getUserDetails } = useUserServices();
  const mounted = useMounted();

  const setProperty = async (newProperty?: Property): Promise<void> => {
    if (formDispatch == null) {
      return;
    }
    const user = await getUserDetails();
    const photoInfo = getInitialPhotoInformation({ user, company, officePhones: newProperty });

    formDispatch({
      type: 'OverrideState',
      payload: Object.assign({
        ...photoInfo,
        type,
        property:
          newProperty === undefined
            ? {
                type: property?.type
              }
            : newProperty,
        directions: newProperty?.directions
      })
    });
    if (type === PhotoRequestType.Residential) {
      setHasCompletedPhotoRequest(
        newProperty?.completedPhotoRequestCount != null &&
          newProperty?.completedPhotoRequestCount > 0
      );
    }
  };

  return (
    <Autocomplete
      options={properties}
      onChange={(e, option: PropertyResponse | null): void => {
        const getProperty = GetProperty.get(type);
        setProperty(undefined);
        if (option != null && getProperty) {
          getProperty(option.id)
            .then((property) => {
              if (mounted.current) {
                setProperty(property);
                setIsCompleteHome(option.isCompleteHome);
              }
            })
            .catch(catchError);
        }
      }}
      getOptionLabel={(option: PropertyResponse): string => option?.name || ''}
      renderInput={(params): JSX.Element => (
        <TextField {...params} variant="outlined" label="Select Property" />
      )}
      renderOption={(props, option): React.ReactNode => (
        <li {...props} key={option.id}>
          {type == PhotoRequestType.Residential ? (
            <Typography>
              {option.mlsNumber != null && <span>({option.mlsNumber}) - </span>}
              {option.name}
              {option.subdivision != null && (
                <span>
                  - <b>{option.subdivision}</b>
                </span>
              )}
              - <b>List Status:</b> {option.mlsStatus}- <b>Completion Stage:</b>{' '}
              {!!option.isCompleteHome ? 'C' : 'I'}
            </Typography>
          ) : (
            <Typography>{option.name}</Typography>
          )}
        </li>
      )}
    />
  );
}
