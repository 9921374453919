import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Description } from '@mui/icons-material';

import { Format } from '@homesusa/core';
import { getBillingReport } from 'core/services/photographer.service';
import {
  BillingSection,
  PhotograpyBillingReportFilter,
  PhotographersBillingReport
} from 'core/interfaces';

import { ReportTypes } from 'modules/photographers/enums';
import { BillingReportGrid } from './billing-report-grid';
import { useStyles } from './photographer-report-grid.styles';
import { ReportFilter } from './report-filter.component';

export const PhotographyBillingReportGrid = (): JSX.Element => {
  const classes = useStyles();
  const [data, setData] = React.useState<PhotographersBillingReport>();
  const [reportRequest, setReportRequest] = React.useState<PhotograpyBillingReportFilter>(
    Object({})
  );

  const handleChange = (name: string, value: unknown): void => {
    setReportRequest((prev) => ({ ...prev, [name]: value }));
  };

  const handleClick = async (): Promise<void> => {
    const photoReport = await getBillingReport(reportRequest);
    setData(photoReport);
  };

  const gridSections = React.useMemo((): BillingSection[] => {
    if (!data) return [];
    const { community, plan, residential, other } = data;
    const { Community, Other, Plan, Residential } = ReportTypes;
    const sections: BillingSection[] = [
      { ...residential, title: 'Residential Billing Report', type: Residential },
      { ...community, title: 'Community Billing Report', type: Community },
      { ...plan, title: 'Plan Billing Report', type: Plan },
      { ...other, title: 'Other Billing Report', type: Other }
    ];
    return sections.filter((section) => section.data.length > 0);
  }, [data]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="h1">Photography Billing Report</Typography>
        <ReportFilter onChange={handleChange} onClick={handleClick} />
      </Box>

      {!!gridSections.length && (
        <Paper elevation={3} sx={classes.reportInformation}>
          <Typography variant="body2">
            <span>Billing Amount:</span> {Format.Money(data?.combinedTotal)}
          </Typography>
        </Paper>
      )}
      {!!gridSections.length ? (
        gridSections.map((section, key) => (
          <BillingReportGrid key={key} billingSection={section} sx={classes.table} />
        ))
      ) : (
        <Box sx={classes.noDataContainer}>
          <Description color="disabled" sx={classes.noDataIcon} />
          <Typography variant="h4" color="GrayText">
            There is no information to show
          </Typography>
        </Box>
      )}
    </Box>
  );
};
