import React, { Fragment } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack
} from '@mui/material';

import { SubmitButton, AppContext, AppType, SubmitIconButton } from '@homesusa/layout';
import { PhotoRequestType, PropertyType } from '@homesusa/photo-request';
import { FormContext } from '@homesusa/form';
import { getVirtualStagingNumber, hasVirtualStaging } from 'core/utils';
import { approvePhotoRequest } from 'core/services/photo-request.service';
import { PhotoFormContextProps, PhotoRequestContext } from 'modules/photo-requests/contexts';
import { CreateVirtualStaging } from 'modules/photo-requests/components';
import { PersistentRightModal } from '@homesusa/core';

function ApproveBasePhotoRequest(): JSX.Element {
  const { getPhotoRequest } = React.useContext(PhotoRequestContext);
  const {
    formState: { id }
  } = React.useContext<PhotoFormContextProps>(FormContext);

  if (id == null) {
    return <Fragment></Fragment>;
  }

  const onApprove = async (): Promise<void> => {
    await approvePhotoRequest(id);
    await getPhotoRequest();
  };

  return (
    <SubmitIconButton onClick={onApprove} title="Approve Request">
      <CheckCircleOutlineIcon />
    </SubmitIconButton>
  );
}

function ApproveResidentialPhotoRequest(): JSX.Element {
  const { getPhotoRequest } = React.useContext(PhotoRequestContext);
  const {
    formState: { id, serviceOptions, property, isVirtualStaging }
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const [openFinishModal, setOpenFinishModal] = React.useState(false);
  const [orderVirtualStagingModal, setOrderVirtualStagingModal] = React.useState(false);
  const closeOrderVirtualStagingModal = (): void => {
    setOrderVirtualStagingModal(false);
    getPhotoRequest();
  };
  const [openCreateVirtualStaging, setOpenCreateVirtualStaging] = React.useState(false);
  const {
    appState: { apps }
  } = React.useContext(AppContext);

  if (id == null) {
    return <Fragment></Fragment>;
  }

  const onApprove = async (): Promise<void> => {
    await approvePhotoRequest(id);
    if (!isVirtualStaging && hasVirtualStaging({ serviceOptions })) {
      setOrderVirtualStagingModal(true);
    } else if (property.type == PropertyType.Residential) {
      window.location.href = `${apps[AppType.Quicklister]}/sale-listings/${property.id}#media`;
    } else {
      await getPhotoRequest();
    }
    setOpenFinishModal(false);
  };

  return (
    <Fragment>
      {property.type == PropertyType.Residential ? (
        <IconButton onClick={(): void => setOpenFinishModal(true)} title="Approve Request">
          <CheckCircleOutlineIcon />
        </IconButton>
      ) : (
        <SubmitIconButton onClick={onApprove} title="Approve Request">
          <CheckCircleOutlineIcon />
        </SubmitIconButton>
      )}

      <Dialog onClose={(): void => setOpenFinishModal(false)} open={openFinishModal}>
        <DialogTitle>Finish</DialogTitle>
        <DialogContent>
          <p>
            Click OK to go to your listing to set photo order, add photo descriptions, and submit
            your photo changes to MLS.
          </p>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <SubmitButton onClick={onApprove}>Ok</SubmitButton>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog
        open={orderVirtualStagingModal}
        onClose={(_, reason): void => {
          if (reason != 'backdropClick') {
            closeOrderVirtualStagingModal();
          }
        }}
      >
        <DialogTitle>Virtual Staging</DialogTitle>
        <DialogContent>
          Do you still want to order Virtual Staging for {getVirtualStagingNumber(serviceOptions)}{' '}
          photo(s)?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeOrderVirtualStagingModal}>No</Button>
          <SubmitButton
            onClick={async (): Promise<void> => {
              setOrderVirtualStagingModal(false);
              setOpenCreateVirtualStaging(true);
            }}
          >
            Yes
          </SubmitButton>
        </DialogActions>
      </Dialog>
      <PersistentRightModal
        title="Virtual Staging"
        open={openCreateVirtualStaging}
        onChange={(value): void => {
          setOpenCreateVirtualStaging(value);
          if (!value) {
            getPhotoRequest();
          }
        }}
      >
        <CreateVirtualStaging />
      </PersistentRightModal>
    </Fragment>
  );
}

export function ApprovePhotoRequest(): JSX.Element {
  const {
    formState: { type }
  } = React.useContext<PhotoFormContextProps>(FormContext);

  return (
    <Fragment>
      {type == PhotoRequestType.Residential ? (
        <ApproveResidentialPhotoRequest />
      ) : (
        <ApproveBasePhotoRequest />
      )}
    </Fragment>
  );
}
