import React, { Fragment, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  PrivateRoute,
  AppContext,
  appPublicRoutesConfig,
  appPrivateRoutesConfig
} from '@homesusa/layout';
import { useHasRole } from '@homesusa/auth';
import { NotesManager } from '@homesusa/notes';
import { FormTabContent } from '@homesusa/form';
import { ErrorBoundary } from '@homesusa/core';

import { PhotoRequestsGrid, PhotoRequestDetails } from 'modules/photo-requests/pages';
import {
  PhotographersGrid,
  PhotographerDetails,
  PhotographersBillingReportsGrid,
  PhotographersPayrollsReportsGrid
} from 'modules/photographers/pages';

import { appState, mlsAdminSideBarItems } from '../app-state';

function AppRoutes(): JSX.Element {
  const { appStateDispatch } = useContext(AppContext);
  const { isMlsAdministrator } = useHasRole();

  React.useEffect(() => {
    const sidebarItems = {
      ...appState.layout.sidebar.items,
      ...(isMlsAdministrator && mlsAdminSideBarItems)
    };
    appStateDispatch({ type: 'UpdateSidebarItem', payload: sidebarItems });
  }, [appStateDispatch, isMlsAdministrator]);

  return (
    <Fragment>
      <Routes>
        {appPublicRoutesConfig.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="/" element={<PrivateRoute />} errorElement={<ErrorBoundary />}>
          {appPrivateRoutesConfig.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="photo-requests/*">
            <Route index element={<PhotoRequestsGrid />} />
            <Route path=":id" element={<PhotoRequestDetails />}>
              <Route path="notes" element={<NotesManager />} />
              <Route index element={<FormTabContent />} />
            </Route>
          </Route>
          <Route path="photographers/*">
            <Route index element={<PhotographersGrid />} />
            <Route path=":id" element={<PhotographerDetails />} />
          </Route>
          <Route
            path="/photographer-billing-reports"
            element={<PhotographersBillingReportsGrid />}
          />
          <Route
            path="/photographer-payroll-reports"
            element={<PhotographersPayrollsReportsGrid />}
          />
          <Route path="/" element={<Navigate to="/photo-requests" />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default AppRoutes;
