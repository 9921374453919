import { PhotoRequestType, PropertyType } from '@homesusa/photo-request';

export const PhotoRequestSourceType = new Map<PhotoRequestType, Record<string, string>>([
  [
    PhotoRequestType.Community,
    {
      [PropertyType.Community]: 'Existing Community',
      [PropertyType.BlankCommunity]: 'Blank Request'
    }
  ],
  [
    PhotoRequestType.Plan,
    {
      [PropertyType.Plan]: 'Existing Plan',
      [PropertyType.BlankPlan]: 'Blank Request'
    }
  ],
  [
    PhotoRequestType.Residential,
    {
      [PropertyType.Residential]: 'Existing Residential',
      [PropertyType.BlankResidential]: 'Blank Request'
    }
  ]
]);
