import { PhotoRequestStatus } from '@homesusa/core';

const SaveStatuses = [
  PhotoRequestStatus.Assigned,
  PhotoRequestStatus.AwaitingApproval,
  PhotoRequestStatus.TripChargePending,
  PhotoRequestStatus.RejectedTripCharge
];
const ReportErrorStatuses = [
  PhotoRequestStatus.Assigned,
  PhotoRequestStatus.AwaitingApproval,
  PhotoRequestStatus.TripChargePending,
  PhotoRequestStatus.RejectedTripCharge,
  PhotoRequestStatus.CancelledTripChargeApproved,
  PhotoRequestStatus.CancelledTripChargeDenied,
  PhotoRequestStatus.PhotosApproved
];
const MessagePhotographerStatuses = [
  PhotoRequestStatus.Assigned,
  PhotoRequestStatus.AwaitingApproval,
  PhotoRequestStatus.RejectedTripCharge,
  PhotoRequestStatus.CancelledTripChargeApproved,
  PhotoRequestStatus.CancelledTripChargeDenied
];

export class MlsAdminStatuses {
  private static EditableStatus = [
    ...SaveStatuses,
    PhotoRequestStatus.Pending,
    PhotoRequestStatus.CancelledTripChargeApproved,
    PhotoRequestStatus.CancelledTripChargeDenied
  ];
  public static Save = MlsAdminStatuses.EditableStatus;
  public static Cancel = MlsAdminStatuses.EditableStatus;
  public static Delete = [...MlsAdminStatuses.EditableStatus, PhotoRequestStatus.PhotosApproved];

  public static AssignPhotographer = [
    PhotoRequestStatus.Pending,
    PhotoRequestStatus.Assigned,
    PhotoRequestStatus.AwaitingApproval,
    PhotoRequestStatus.RejectedTripCharge,
    PhotoRequestStatus.PhotosRejected,
    PhotoRequestStatus.TripChargePending
  ];

  public static MessagePhotographer = [
    PhotoRequestStatus.TripChargePending,
    ...MessagePhotographerStatuses
  ];

  public static RequestTripCharge = [
    PhotoRequestStatus.Assigned,
    PhotoRequestStatus.AwaitingApproval,
    PhotoRequestStatus.CancelledTripChargeDenied
  ];

  public static ApproveOrCancelTripCharge = [
    PhotoRequestStatus.TripChargePending,
    PhotoRequestStatus.RejectedTripCharge
  ];
}

export class CompanyEmployeeStatuses {
  public static Save = [PhotoRequestStatus.Pending, ...SaveStatuses];
  public static ReportError = [PhotoRequestStatus.Pending, ...ReportErrorStatuses];
  public static MessagePhotographer = MessagePhotographerStatuses;
}

export class PhotographerStatuses {
  public static Save = SaveStatuses;
  public static ReportError = ReportErrorStatuses;
  public static Cancel = [PhotoRequestStatus.Assigned];
  public static RequestTripCharge = [PhotoRequestStatus.Assigned];
}
