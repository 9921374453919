import React, { Fragment } from 'react';
import { Box } from '@mui/material';

import { Loader } from '@homesusa/core';
import { PhotoRequestForm, PhotoRequestType } from '@homesusa/photo-request';
import { SubmitButton } from '@homesusa/layout';
import { FormContext, FormStyled, FormErrorsButton } from '@homesusa/form';
import { PhotoFormContextProps } from '../../contexts';
import { useCreatePhotoRequest } from '../../hooks';
import { Modal } from '../buttons/modal.component';

export function PhotoForm(): JSX.Element {
  const {
    formState,
    otherProps: {
      hasCompletedPhotoRequest,
      company: { photographyServiceInfo }
    }
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const onSave = useCreatePhotoRequest();
  const [showModal, setShowModal] = React.useState(false);

  if (!photographyServiceInfo) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Loader />
      </Box>
    );
  }

  const checkCompletedPhotoRequest = (): Promise<void> => {
    if (
      formState.type === PhotoRequestType.Residential &&
      hasCompletedPhotoRequest &&
      formState.serviceOptions?.some((serviceOption) => serviceOption === 'stillsHome')
    ) {
      setShowModal(true);
      return Promise.resolve();
    }

    return onSave();
  };

  return (
    <Fragment>
      <FormErrorsButton />
      <FormStyled>
        <form>
          <PhotoRequestForm />
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <SubmitButton onClick={checkCompletedPhotoRequest}>Submit</SubmitButton>
          </Box>
        </form>
      </FormStyled>
      <Fragment>
        <Modal
          dialogProps={{
            open: showModal,
            onClose: (): void => setShowModal(false)
          }}
          title="Previous Photo Request(s) Found"
          actionButton={{
            text: 'Yes',
            method: async (): Promise<void> => {
              await onSave();
              setShowModal(false);
            }
          }}
        >
          <Fragment>
            <p>
              One or more requests have already been submitted for this listing. Additional photo
              requests may be subject to a ${photographyServiceInfo.stillHome} fee.
            </p>
            <p>Do you want to continue?</p>
          </Fragment>
        </Modal>
      </Fragment>
    </Fragment>
  );
}
