import React from 'react';
import { Chip } from '@mui/material';

import { FormToolbar as HusaFormToolbar } from '@homesusa/form';
import { useDocumentTitle } from '@homesusa/layout';
import { PhotoRequestStatusLabel } from '@homesusa/core';
import { usePhotoRequestTitle, useToolbarButtons } from 'modules/photo-requests/hooks';
import { PhotoRequestContext } from '../../contexts';

export function FormToolbar(): JSX.Element {
  const { photoRequest } = React.useContext(PhotoRequestContext);
  const toolbarButtons = useToolbarButtons();
  const title = usePhotoRequestTitle();
  useDocumentTitle(title);

  return (
    <HusaFormToolbar>
      <HusaFormToolbar.Left title={title}>
        <Chip
          label={PhotoRequestStatusLabel.get(photoRequest.status)}
          sx={{
            backgroundColor: 'primary.dark',
            color: 'white'
          }}
        />
      </HusaFormToolbar.Left>
      <HusaFormToolbar.Right>
        {Object.entries(toolbarButtons).map(
          ([key, button]) => button.show && <button.component key={key} />
        )}
      </HusaFormToolbar.Right>
    </HusaFormToolbar>
  );
}
