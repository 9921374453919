import { SxProps } from '@mui/system';

interface Styles {
  root: SxProps;
  form: SxProps;
}

export const reportFilterStyles = (): Styles => {
  return {
    root: { maxWidth: 'auto', padding: '8px 8px' },
    form: { display: 'flex', columnGap: 1 }
  };
};
