import React from 'react';

import { PhotographerService } from 'core/services';
import { PhotographerContext, PhotographerFormProps } from '../contexts';
import { FormContext, useGetFormErrors } from '@homesusa/form';
import { AppContext } from '@homesusa/layout';

export const useSubmitPhotographerServices = (): {
  onSave: () => Promise<void>;
  onUpdate: () => Promise<void>;
} => {
  const { photographer, getPhotographerServices } = React.useContext(PhotographerContext);
  const { formState } = React.useContext<PhotographerFormProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return;
    }

    return PhotographerService.createPhotographerService(photographer.id, formState)
      .then(() => {
        addAlert({
          message: 'Service was added successfully',
          variant: 'success'
        });
      })
      .catch(() => {
        addAlert({
          message: 'An error occurred adding the service',
          variant: 'error'
        });
      });
  };

  const onUpdate = async (): Promise<void> => {
    if (hasErrors()) {
      return;
    }

    return PhotographerService.updatePhotographerService(photographer.id, formState)
      .then(() => {
        addAlert({
          message: 'Service was updated successfully',
          variant: 'success'
        });
        getPhotographerServices();
      })
      .catch(() => {
        addAlert({
          message: 'An error occurred updating the service',
          variant: 'error'
        });
      });
  };

  return { onSave, onUpdate };
};
