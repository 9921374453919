import React from 'react';
import { Column } from '@homesusa/grid-table';
import { PhotographerReport } from 'core/interfaces';
import { ReportTypes, columnsByReportType, photographerReportColumns } from '../enums';

export const usePhotographersReportsColumns = (type: ReportTypes): Column<PhotographerReport>[] => {
  const columns: Array<Column<PhotographerReport>> = React.useMemo(() => {
    const columsByType = columnsByReportType[type];
    return columsByType.map((column) => photographerReportColumns[column]);
  }, []);

  return columns;
};
