import React from 'react';

import { Save } from '@mui/icons-material';

import { FormContext, FormToolbar as HusaFormToolbar } from '@homesusa/form';
import { SubmitButton } from '@homesusa/layout';
import { PhotographerContext, PhotographerFormProps } from '../contexts';
import { useSubmitPhotographerServices } from '../hooks';

export const PhotographerDetailsToolbar = (): JSX.Element => {
  const { photographer, photographerServices } = React.useContext(PhotographerContext);
  const { formState } = React.useContext<PhotographerFormProps>(FormContext);
  const { onSave, onUpdate } = useSubmitPhotographerServices();

  const hasServicesCurrentMarket = React.useMemo(
    () => !!photographerServices.find((services) => services.market === formState.market),
    [formState.market]
  );

  const title = `Photographer - ${photographer.firstName} ${photographer.lastName} (${photographer.userName})`;
  return (
    <HusaFormToolbar>
      <HusaFormToolbar.Left title={title} />
      <HusaFormToolbar.Right>
        <SubmitButton
          buttonProps={{
            startIcon: <Save />
          }}
          onClick={hasServicesCurrentMarket ? onUpdate : onSave}
        >
          Save
        </SubmitButton>
      </HusaFormToolbar.Right>
    </HusaFormToolbar>
  );
};
