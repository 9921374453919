import React from 'react';

import { AppContext } from '@homesusa/layout';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from '../contexts';
import { Photographer } from 'core/interfaces';

export const useAssignPhotographer = (): ((userSelected?: Photographer) => Promise<void>) => {
  const { photoRequest, getPhotoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const assignPhotographer = async (userSelected?: Photographer): Promise<void> => {
    if (!photoRequest || !userSelected || !photoRequest.id) {
      return;
    }

    await PhotoRequestService.assignPhotographer({
      photoRequestId: photoRequest.id,
      userId: userSelected.id
    });

    addAlert({
      message: 'The photographer was assigned',
      variant: 'success'
    });

    await getPhotoRequest(true);
  };

  return assignPhotographer;
};
