import React from 'react';

import { PhotographerService } from 'core/services';
import { Photographer, PhotographerServicesRequest } from 'core/interfaces';

export const useGetPhotographer = (
  photographerId: string
): {
  photographer?: Photographer;
  getPhotographer: () => Promise<Photographer>;
  photographerServices?: PhotographerServicesRequest[];
  getPhotographerServices: () => Promise<PhotographerServicesRequest[]>;
} => {
  const [photographer, setPhotographer] = React.useState<Photographer>();
  const [photographerServices, setPhotographerServices] =
    React.useState<PhotographerServicesRequest[]>();

  const getPhotographer = React.useCallback(
    () =>
      PhotographerService.getPhotographer(photographerId).then((photographer: Photographer) => {
        setPhotographer(photographer);
        return photographer;
      }),
    [photographerId]
  );

  const getPhotographerServices = React.useCallback(
    () =>
      PhotographerService.getPhothographerServices(photographerId).then((services) => {
        setPhotographerServices(services);
        return services;
      }),
    [photographerId]
  );

  return { photographer, getPhotographer, photographerServices, getPhotographerServices };
};
