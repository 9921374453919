import { Format, MarketCode, MarketCodeLabel } from '@homesusa/core';
import { PhotographerReport } from 'core/interfaces';
import { ReportColumnNames } from './column-types-reports.enum';
import { getOrdinalSuffix } from 'core/utils';

export const photographerReportColumns = {
  [ReportColumnNames.MlsNumber]: {
    Header: 'MLS #',
    accessor: (data: PhotographerReport): string | number => data.mlsNumber ?? '',
    tooltip: 'MLS Number'
  },
  [ReportColumnNames.Address]: {
    Header: 'Address',
    accessor: (data: PhotographerReport): string => data.address ?? ''
  },
  [ReportColumnNames.BuilderName]: {
    Header: 'Builder',
    accessor: (data: PhotographerReport): string => data.builderName ?? ''
  },
  [ReportColumnNames.Subdivision]: {
    Header: 'Subdivision',
    accessor: (data: PhotographerReport): string => data.subdivision ?? ''
  },
  [ReportColumnNames.Market]: {
    Header: 'Market',
    accessor: (data: PhotographerReport): string =>
      MarketCodeLabel.get(data.marketCode as MarketCode) ?? ''
  },
  [ReportColumnNames.CompletedOn]: {
    Header: 'Completed On',
    accessor: (data: PhotographerReport): string => Format.DateTime(data.completedOn)
  },
  [ReportColumnNames.Order]: {
    Header: '2nd + Request',
    accessor: (data: PhotographerReport): string => getOrdinalSuffix(data.order)
  },
  [ReportColumnNames.StillsHome]: {
    Header: 'Still Home',
    accessor: (data: PhotographerReport): string => Format.Money(data.stillsHome)
  },
  [ReportColumnNames.StillsExterior]: {
    Header: 'Still Exterior',
    accessor: (data: PhotographerReport): string => Format.Money(data.stillExterior)
  },
  [ReportColumnNames.VirtualTour]: {
    Header: 'Virtual Tour',
    accessor: (data: PhotographerReport): string => Format.Money(data.virtualTour)
  },
  [ReportColumnNames.TripCharge]: {
    Header: 'Trip Charge',
    accessor: (data: PhotographerReport): string => Format.Money(data.tripCharge)
  },
  [ReportColumnNames.Twilight]: {
    Header: 'Twilight',
    accessor: (data: PhotographerReport): string => Format.Money(data.twilight)
  },
  [ReportColumnNames.VirtualStagingOneImage]: {
    Header: 'VS 1',
    headertooltip: 'Virtual Staging 1 Image',
    accessor: (data: PhotographerReport): string => Format.Money(data.virtualStagingOneImage)
  },
  [ReportColumnNames.VirtualStagingThreeImage]: {
    Header: 'VS 3',
    headertooltip: 'Virtual Staging 3 Images',
    accessor: (data: PhotographerReport): string => Format.Money(data.virtualStagingThreeImage)
  },
  [ReportColumnNames.PhotoshopEditing]: {
    Header: 'Photoshop',
    headertooltip: 'Photoshop Editing',
    accessor: (data: PhotographerReport): string => Format.Money(data.photoshopEditing)
  },
  [ReportColumnNames.VirtualStagingSixImage]: {
    Header: 'VS 6',
    headertooltip: 'Virtual Staging 6 Images',
    accessor: (data: PhotographerReport): string => Format.Money(data.virtualStagingSixImage)
  },
  [ReportColumnNames.Total]: {
    Header: 'Total',
    accessor: (data: PhotographerReport): string => Format.Money(data.total)
  },
  [ReportColumnNames.CommunityOption]: {
    Header: 'CO 1',
    headertooltip: 'Community Option 1',
    accessor: (data: PhotographerReport): string => Format.Money(data.communityOption)
  },
  [ReportColumnNames.CommunityOption2]: {
    Header: 'CO 2',
    headertooltip: 'Community Option 2',
    accessor: (data: PhotographerReport): string => Format.Money(data.communityOption2)
  },
  [ReportColumnNames.CommunityOption3]: {
    Header: 'CO 3',
    headertooltip: 'Community Option 3',
    accessor: (data: PhotographerReport): string => Format.Money(data.communityOption3)
  },
  [ReportColumnNames.PlanName]: {
    Header: 'Plan Name',
    accessor: (data: PhotographerReport): string => data.planName ?? ''
  }
};
