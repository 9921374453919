import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, LinearProgress } from '@mui/material';

import { PhotographerContext } from '../contexts';
import { useGetPhotographer } from '../hooks';
import { PhotographerFormProvider } from './photographer-form.provider';

const LocalProvider = ({
  children,
  photoRequestId
}: {
  children: React.ReactNode;
  photoRequestId: string;
}): JSX.Element => {
  const { photographer, getPhotographer, getPhotographerServices, photographerServices } =
    useGetPhotographer(photoRequestId);

  React.useEffect(() => {
    getPhotographer();
    getPhotographerServices();
  }, [getPhotographer]);

  if (!photographer || !photographerServices) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <PhotographerContext.Provider
      value={{ photographer, getPhotographer, getPhotographerServices, photographerServices }}
    >
      <PhotographerFormProvider>{children}</PhotographerFormProvider>
    </PhotographerContext.Provider>
  );
};

export const PhotographerProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const params = useParams<{ id: string }>();
  if (params.id == null) {
    return <></>;
  }
  return <LocalProvider photoRequestId={params.id}>{children}</LocalProvider>;
};
