import React from 'react';

import { AppContext } from '@homesusa/layout';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from '../contexts';

export const useCancelRequest = (): ((cancelledReason: string) => Promise<void>) => {
  const { photoRequest, getPhotoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const cancelRequest = async (cancelledReason: string): Promise<void> => {
    if (!photoRequest || cancelledReason === '') {
      return;
    }

    await PhotoRequestService.cancelPhotoRequest(photoRequest, cancelledReason).then(() => {
      getPhotoRequest();
    });

    addAlert({
      message: 'The request was cancelled',
      variant: 'success'
    });
  };

  return cancelRequest;
};
