import axios from 'axios';
import { Resources } from '@homesusa/media';

const apiUrl = process.env.REACT_APP_API_URL + 'api/photo-requests';

const getAll = async (id: string): Promise<Resources> => {
  return axios.get<Resources>(`${apiUrl}/${id}/media`).then((response) => response.data);
};

export { getAll };
