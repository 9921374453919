import React from 'react';
import { useParams } from 'react-router-dom';

import { PhotoRequestForm } from '../components';

export function PhotoRequestDetails(): JSX.Element {
  const params = useParams<{ id: string }>();
  if (params.id == null) {
    return <></>;
  }
  return <PhotoRequestForm photoRequestId={params.id} />;
}
