import React, { ChangeEvent } from 'react';
import { Grid, TextField } from '@mui/material';

import { useHasRole } from '@homesusa/auth';
import { MediaManager } from '@homesusa/media';
import { FormContext } from '@homesusa/form';
import { RoleEmployee, PhotoRequestStatus } from '@homesusa/core';
import { PhotoFormContextProps, PhotoRequestContext } from 'modules/photo-requests/contexts';

export function PhotoMediaManager(): JSX.Element {
  const {
    photoRequest: { status }
  } = React.useContext(PhotoRequestContext);
  const {
    formDispatch,
    formState: { highResolutionUrl }
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const { hasEmployeeRole } = useHasRole();

  return (
    <MediaManager>
      <Grid container mt={0.5}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="High Resolution URL"
            defaultValue={highResolutionUrl}
            variant="outlined"
            disabled={
              [PhotoRequestStatus.PhotosApproved, PhotoRequestStatus.Cancelled].some(
                (photoStatus) => photoStatus == status
              ) || hasEmployeeRole([RoleEmployee.Readonly, RoleEmployee.CompanyAdminReadonly])
            }
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              if (formDispatch) {
                formDispatch({
                  type: 'FormChangeByInputName',
                  inputName: 'highResolutionUrl',
                  payload: event.target.value
                });
              }
            }}
            InputProps={{
              inputProps: {
                maxLength: 200
              }
            }}
          />
        </Grid>
      </Grid>
      <MediaManager.Toolbar />
    </MediaManager>
  );
}
