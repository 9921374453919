import React from 'react';

import { Dialog, DialogContent } from '@mui/material';

export function PhotographyServiceDisabledMessage({
  showModal,
  onCloseModal
}: {
  showModal: boolean;
  onCloseModal: () => void;
}): JSX.Element {
  return (
    <Dialog open={showModal} onClose={onCloseModal}>
      <DialogContent>
        Photography service is currently disabled. To activate, please contact the MLS Department.
      </DialogContent>
    </Dialog>
  );
}
