import React from 'react';
import { Stack, SxProps } from '@mui/material';

import { Columns, ExportButtons, GridProvider, GridTable } from '@homesusa/grid-table';
import { Format, MarketCodeLabel } from '@homesusa/core';
import { PhotoRequestPayroll } from 'core/interfaces';

export const PayrollReportGrid = ({
  payrollSection,
  sx,
  fetchDataForDownloading
}: {
  payrollSection: PhotoRequestPayroll[];
  sx: SxProps;
  fetchDataForDownloading: () => Promise<PhotoRequestPayroll[]>;
}): JSX.Element => {
  const columns: Columns<PhotoRequestPayroll> = [
    {
      Header: 'Photographer',
      accessor: 'photographer'
    },
    {
      Header: 'Builder',
      accessor: 'builder'
    },
    {
      Header: 'Market',
      accessor: ({ market }): string => MarketCodeLabel.get(market) ?? market
    },
    {
      Header: 'Address',
      accessor: 'address'
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'Created Date',
      accessor: ({ createdDate }): string => Format.Date(createdDate)
    },
    {
      Header: 'Completed Date',
      accessor: ({ completedDate }): string => Format.Date(completedDate)
    },
    {
      Header: 'Completed By',
      accessor: 'completedByName'
    },
    {
      Header: 'Still Images',
      accessor: ({ stillImages }): string => Format.Money(stillImages)
    },
    {
      Header: 'Virtual Tour',
      accessor: ({ virtualTour }): string => Format.Money(virtualTour)
    },
    {
      Header: 'Trip Charge',
      accessor: ({ tripCharge }): string => Format.Money(tripCharge)
    },
    {
      Header: 'Twilight (3 Images)',
      accessor: ({ twilight }): string => Format.Money(twilight)
    },
    {
      Header: 'Virtual Staging 1 Photo',
      accessor: ({ virtualStagingOnePhoto }): string => Format.Money(virtualStagingOnePhoto)
    },
    {
      Header: 'Virtual Staging 3 Photos',
      accessor: ({ virtualStagingThreePhoto }): string => Format.Money(virtualStagingThreePhoto)
    },
    {
      Header: 'Virtual Staging 6 Photos',
      accessor: ({ virtualStagingSixPhoto }): string => Format.Money(virtualStagingSixPhoto)
    },
    {
      Header: 'Photoshop Editing',
      accessor: ({ photoshopEditing }): string => Format.Money(photoshopEditing)
    },
    {
      Header: 'Total',
      accessor: ({ total }): string => Format.Money(total)
    }
  ];

  return (
    <GridProvider
      options={{
        columns: columns,
        data: payrollSection,
        showHeaderTooltip: true,
        showCellTooltip: true
      }}
      hidePagination
      totalRows={payrollSection.length}
    >
      <Stack direction="row" justifyContent="flex-end" mt={2} mb={2}>
        <ExportButtons onClick={fetchDataForDownloading} fileName="Photographer Payroll" />
      </Stack>
      <GridTable sx={sx} />
    </GridProvider>
  );
};
