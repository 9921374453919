import React from 'react';
import { Typography, SxProps, Box } from '@mui/material';

import { Format } from '@homesusa/core';
import { ExportButtons, GridProvider, GridTable } from '@homesusa/grid-table';

import { AccordionBox } from 'core/components';
import { BillingSection, PhotographerReport } from 'core/interfaces';
import { usePhotographersReportsColumns } from 'modules/photographers/hooks';
import { StackContainer } from './photographer-report-grid.styles';

const HeaderGrid = ({
  title,
  sectionTotal
}: {
  title: string;
  sectionTotal: number;
}): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      sx={{ padding: '0 !important' }}
    >
      <Typography fontWeight="bold">{title}</Typography>
      <Typography fontWeight="bold">Total: {Format.Money(sectionTotal)}</Typography>
    </Box>
  );
};

export const BillingReportGrid = ({
  billingSection,
  sx
}: {
  billingSection: BillingSection;
  sx: SxProps;
}): JSX.Element => {
  const { sectionTotal, title, data, type } = billingSection;
  const columns = usePhotographersReportsColumns(type);

  return (
    <AccordionBox title={<HeaderGrid title={title} sectionTotal={sectionTotal} />}>
      <GridProvider
        options={{
          columns: columns,
          data: data,
          showHeaderTooltip: true,
          showCellTooltip: true
        }}
        hidePagination
        totalRows={data.length}
      >
        <StackContainer direction="row" justifyContent="flex-end" mt={2} mb={2}>
          <ExportButtons
            onClick={async (): Promise<PhotographerReport[]> => data}
            fileName="Photography Billing Report"
          />
        </StackContainer>
        <GridTable sx={sx} />
      </GridProvider>
    </AccordionBox>
  );
};
