import React from 'react';
import { Note as NoteIcon } from '@mui/icons-material';

import { NotesContext } from '@homesusa/notes';
import { FormTabsMenuOption } from '@homesusa/form';

export const useNotesMenuButton = (): FormTabsMenuOption => {
  const { notes } = React.useContext(NotesContext);

  const option: FormTabsMenuOption = React.useMemo(
    () => ({
      endpoint: 'notes',
      icon: NoteIcon,
      label: 'Notes',
      color: notes.length > 0 ? 'warning' : undefined
    }),
    [notes]
  );

  return option;
};
