import React from 'react';
import { Column } from '@homesusa/grid-table';
import { Photographer } from 'core/interfaces';
import { Format } from '@homesusa/core';

export const usePhotographersColumns = (): Column<Photographer>[] => {
  const columns: Array<Column<Photographer>> = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: (data: Photographer): string => data.firstName ?? ''
      },
      {
        Header: 'Last Name',
        accessor: (data: Photographer): string => data.lastName ?? ''
      },
      {
        Header: 'Username',
        accessor: (data: Photographer): string => data.email ?? ''
      },
      {
        Header: 'Business Phone',
        accessor: (data: Photographer): string => Format.PhoneNumber(data.businessPhone)
      },
      {
        Header: 'Mobile Phone',
        accessor: (data: Photographer): string => Format.PhoneNumber(data.mobilePhone)
      }
    ],
    []
  );

  return columns;
};
