import React from 'react';
import { Box, LinearProgress } from '@mui/material';

import { PhotoRequestFormProvider, Company } from '@homesusa/photo-request';
import { NotesProvider } from '@homesusa/notes';
import { MediaProvider, getMediaEndpoints } from '@homesusa/media';
import { FormStyled, FormTabsErrorsButton } from '@homesusa/form';
import { RoleEmployee } from '@homesusa/core';
import { useHasRole } from '@homesusa/auth';
import { getCompanyInfo } from 'core/services/company.service';
import { PhotoRequestContext, PhotoFormOtherContextProps } from '../../contexts';
import { FormToolbar } from './form-toolbar.component';
import { PhotoFormTabLabel } from '../../enums';
import { PhotoFormTabs } from './form-tabs.component';
import { useGetPhotoRequest } from 'modules/photo-requests/hooks';
import mapperJson from '../../rules/form-mapper.json';

export function PhotoRequestForm({ photoRequestId }: { photoRequestId: string }): JSX.Element {
  const { photoRequest, getPhotoRequest, completedCount } = useGetPhotoRequest(photoRequestId);
  const [companyDetails, setCompanyDetails] = React.useState<Company>();
  const getCompanyDetails = async (companyId: string): Promise<Company> => {
    if (companyDetails && companyId == companyDetails.id) {
      return companyDetails;
    }
    const company = await getCompanyInfo(companyId);
    setCompanyDetails(company);
    return company;
  };
  const { hasEmployeeRole } = useHasRole();
  const readOnly = React.useMemo(
    () => hasEmployeeRole([RoleEmployee.Readonly, RoleEmployee.CompanyAdminReadonly]),
    []
  );

  const apiUrl = React.useMemo(
    () => `${process.env.REACT_APP_API_URL}api/photo-requests/${photoRequestId}`,
    [photoRequestId]
  );
  const mediaEndpoints = React.useMemo(() => getMediaEndpoints(apiUrl), [photoRequestId]);

  React.useEffect(() => {
    getPhotoRequest();
  }, [getPhotoRequest]);

  React.useEffect(() => {
    if (photoRequest?.companyId) {
      getCompanyDetails(photoRequest.companyId);
    }
  }, [photoRequest?.companyId]);

  if (!photoRequest || completedCount == null || !companyDetails) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <PhotoRequestContext.Provider value={{ photoRequest, getPhotoRequest }}>
      <NotesProvider baseUrl={apiUrl} initialReadOnly={readOnly}>
        <MediaProvider
          entityId={photoRequestId}
          options={{ maxMedia: 60, endpoints: mediaEndpoints, readOnly }}
        >
          <PhotoRequestFormProvider<PhotoFormOtherContextProps>
            initialData={photoRequest}
            otherContextProps={{
              getCompanyDetails,
              fullPage: true,
              hasCompletedPhotoRequest: completedCount > 0,
              company: companyDetails
            }}
          >
            <FormToolbar />
            <FormTabsErrorsButton labelMap={PhotoFormTabLabel} formMapper={mapperJson.form} />
            <FormStyled>
              <PhotoFormTabs />
            </FormStyled>
          </PhotoRequestFormProvider>
        </MediaProvider>
      </NotesProvider>
    </PhotoRequestContext.Provider>
  );
}
