import React from 'react';

import { FormProvider, flattenRules } from '@homesusa/form';
import { PhotographerServicesRequest } from 'core/interfaces';
import { photographerRules } from '../rules/photographers-rules';
import { PhotographerContext, PhotographerFormActions, photographerFormReducer } from '../contexts';

export const PhotographerFormProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  //Form state
  const { photographer } = React.useContext(PhotographerContext);
  return (
    <FormProvider<PhotographerServicesRequest, PhotographerFormActions>
      initialFormState={Object({ photographer })}
      initialFormRules={flattenRules(photographerRules)}
      formReducer={photographerFormReducer}
    >
      {children}
    </FormProvider>
  );
};
