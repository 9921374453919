import React, { Fragment } from 'react';

import { FormContext } from '@homesusa/form';
import { useMounted } from '@homesusa/core';
import { PhotoRequestType } from '@homesusa/photo-request';
import { PropertyResponse } from 'core/interfaces';
import { SearchProfileProperties, SearchResidentialProperties } from 'core/components';
import { PhotoFormContextProps } from 'modules/photo-requests/contexts';
import { SelectProperty } from './select-property.component';
import { Stack, Typography } from '@mui/material';

export function SearchProperties(): JSX.Element {
  const [properties, setProperties] = React.useState<PropertyResponse[]>();
  const {
    formState: { companyId, type, property },
    formDispatch
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const mounted = useMounted();
  const onSearch = (response: PropertyResponse[]): void => {
    setProperties(undefined);
    if (formDispatch) {
      formDispatch({
        type: 'FormChangeByInputName',
        inputName: 'property',
        payload: {
          type: property?.type
        }
      });
    }
    if (mounted.current) {
      setProperties(response);
    }
  };

  if (companyId == null || type == null) {
    return <Fragment></Fragment>;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Search:</Typography>
      {type == PhotoRequestType.Residential ? (
        <SearchResidentialProperties companyId={companyId} onSearch={onSearch} />
      ) : (
        <SearchProfileProperties companyId={companyId} onSearch={onSearch} type={type} />
      )}
      {properties != null && <SelectProperty properties={properties} />}
    </Stack>
  );
}
