import React from 'react';
import { FormErrorsButton, FormStyled } from '@homesusa/form';
import { PhotographerForm, PhotographerDetailsToolbar } from '../components';
import { PhotographerProvider } from '../providers';

export const PhotographerDetails = (): JSX.Element => {
  return (
    <PhotographerProvider>
      <PhotographerDetailsToolbar />
      <FormErrorsButton />
      <FormStyled>
        <PhotographerForm />
      </FormStyled>
    </PhotographerProvider>
  );
};
