import React from 'react';

import { AppContext } from '@homesusa/layout';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from '../contexts';

export const useReportError = (): ((message: string) => Promise<void>) => {
  const { photoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const reportError = async (message: string): Promise<void> => {
    if (!photoRequest || message === '') {
      return;
    }

    await PhotoRequestService.reportError(photoRequest, message);
    addAlert({
      message: 'The error was reported',
      variant: 'success'
    });
  };

  return reportError;
};
