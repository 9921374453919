import { formContextReducer, FormContextActions } from '@homesusa/form';
import { PhotographyServicePricesInfo } from '@homesusa/photo-request';
import { PhotographerServicesRequest } from 'core/interfaces';

export type PhotographerFormActions =
  | FormContextActions<PhotographerServicesRequest>
  | {
      type: 'UpdateServicePrices';
      payload: PhotographyServicePricesInfo;
    };

export const photographerFormReducer = (
  state: PhotographerServicesRequest,
  action: PhotographerFormActions
): PhotographerServicesRequest => {
  switch (action.type) {
    case 'UpdateServicePrices':
      return {
        market: state.market,
        ...action.payload
      };
    default: {
      return formContextReducer(state, action);
    }
  }
};
