import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { SectionBox } from '@homesusa/core';
import { NumberInput } from '@homesusa/form';

export const PhotographerServices = (): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <SectionBox title="Services Requested">
          <Grid container>
            <Grid item xs={6}>
              <NumberInput name="stillHome" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput name="stillExteriorOnly" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Twilight</Typography>
            </Grid>
            <Grid item xs={6}>
              <NumberInput name="twilight" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput name="virtualTour" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Virtual Staging</Typography>
            </Grid>
            <Grid item xs={4}>
              <NumberInput name="virtualStagingOneImage" />
            </Grid>
            <Grid item xs={4}>
              <NumberInput name="virtualStagingThreeImage" />
            </Grid>
            <Grid item xs={4}>
              <NumberInput name="virtualStagingSixImage" />
            </Grid>
          </Grid>
        </SectionBox>
      </Grid>
      <Grid item xs={5}>
        <Stack spacing={2}>
          <SectionBox title="Community Options">
            <Grid container>
              <Grid item xs={6}>
                <NumberInput
                  name="communityOptionOne"
                  tooltip="Four to five photos when ordered at the same time as the photography of a home in that community. Included are gate or entrance, pool, community center and playground with retouching of green grass and blue sky."
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  name="communityOptionTwo"
                  tooltip="Up to twenty-five photos with retouching of green grass and blue sky."
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  name="communityOptionThree"
                  tooltip="Up to twenty photos depending on the number of amenities with retouching for green grass, blue pool water and blue skies. If the recreational center has TVs, image are added to the TVs and fires are added in the fireplaces and fire pits."
                />
              </Grid>
            </Grid>
          </SectionBox>
          <SectionBox title="Trip Charge">
            <Grid container>
              <Grid item xs={6}>
                <NumberInput name="tripCharge" />
              </Grid>
            </Grid>
          </SectionBox>
          <SectionBox title="Photoshop">
            <Grid container>
              <Grid item xs={6}>
                <NumberInput name="photoshopEditing" />
              </Grid>
            </Grid>
          </SectionBox>
        </Stack>
      </Grid>
    </Grid>
  );
};
