import React from 'react';

import { getPhotographers } from 'core/services/photographer.service';
import { Photographer } from 'core/interfaces';

// eslint-disable-next-line
export const useGetPhotographers = () => {
  const [users, setUsers] = React.useState<Photographer[]>([]);
  const [loading, setLoading] = React.useState(false);

  const getUsers = React.useCallback(() => {
    setLoading(true);
    getPhotographers().then((response) => {
      setUsers(response.data);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  return { loading, users };
};
