export enum PhotoFormTab {
  General = 'general',
  Communication = 'communication',
  Photographer = 'photographer',
  Media = 'media'
}

export const PhotoFormTabLabel = new Map<PhotoFormTab, string>([
  [PhotoFormTab.General, 'General'],
  [PhotoFormTab.Communication, 'Communication'],
  [PhotoFormTab.Photographer, 'Photographer'],
  [PhotoFormTab.Media, 'Media']
]);
