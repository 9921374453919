import React, { Fragment } from 'react';
import { DeleteOutlined } from '@mui/icons-material';

import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { useDeleteRequest } from '../../hooks';

export function DeletePhotoRequest(): JSX.Element {
  const deleteRequest = useDeleteRequest();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Delete Request">
        <DeleteOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Delete Request"
        actionButton={{
          text: 'Delete',
          method: async (): Promise<void> => {
            await deleteRequest();
            setShowModal(false);
          }
        }}
      >
        <p>Are you sure you want to delete this request?</p>
      </Modal>
    </Fragment>
  );
}
