import React, { Fragment } from 'react';
import { TextField } from '@mui/material';
import { KeyboardReturnOutlined } from '@mui/icons-material';

import { IconButton } from 'core/components';
import { TripChargeService } from 'core/services';
import { Modal } from './modal.component';
import { useTripChargeReason } from '../../hooks';

export function RejectTripCharge(): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  const { onSubmit, hasError, onChangeReason } = useTripChargeReason(
    'The Photo Request was successfully Rejected Trip Charge',
    TripChargeService.rejectTripCharge
  );

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Reject Trip Charge">
        <KeyboardReturnOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Trip Charge Rejected"
        actionButton={{
          text: 'Submit',
          method: async (): Promise<void> => {
            const success = await onSubmit();
            if (success) {
              setShowModal(false);
            }
          }
        }}
      >
        <TextField
          fullWidth
          label="What is the reason you wish to Rejected the Trip Charge for this photo request?"
          multiline
          variant="outlined"
          name="reason"
          rows={4}
          onChange={(event): void => onChangeReason(event.target.value)}
          helperText={hasError ? 'Enter a reason for rejecting trip charge.' : undefined}
          error={hasError}
        />
      </Modal>
    </Fragment>
  );
}
