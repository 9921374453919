import React from 'react';

import { AppContext } from '@homesusa/layout';
import { TripChargeService } from 'core/services';
import { PhotoRequestContext } from '../contexts';

export const useApproveTripCharge = (): (() => Promise<void>) => {
  const { photoRequest, getPhotoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const sendTripCharge = async (): Promise<void> => {
    if (!photoRequest) {
      return;
    }

    return TripChargeService.approveTripCharge(photoRequest).then((updated) => {
      if (updated) {
        addAlert({
          message: 'The Trip Charge was successfully Approved',
          variant: 'success'
        });
        getPhotoRequest();
      }
    });
  };

  return sendTripCharge;
};
