import React from 'react';

import { FormContext } from '@homesusa/form';
import { AppContext } from '@homesusa/layout';
import { useFormErrors } from '@homesusa/photo-request';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from '../contexts';

export const useUpdatePhotoRequest = (): (() => Promise<void>) => {
  const { addAlert } = React.useContext(AppContext);
  const { getPhotoRequest } = React.useContext(PhotoRequestContext);
  const { formState } = React.useContext(FormContext);
  const hasError = useFormErrors();

  const onSave = async (): Promise<void> => {
    if (!formState || !formState.id || hasError()) {
      return;
    }

    return PhotoRequestService.updatePhotoRequest(formState).then((updated: boolean) => {
      if (updated) {
        addAlert({
          message: 'The request was successfully updated',
          variant: 'success'
        });
        getPhotoRequest();
      }
    });
  };

  return onSave;
};
