import React, { Fragment } from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { AppContext } from '@homesusa/layout';
import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { PhotoRequestContext } from 'modules/photo-requests/contexts';
import { PhotoRequestService } from 'core/services';

export function UnlockPhotoRequest(): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  const {
    photoRequest: { id }
  } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const unlockRequest = async (): Promise<void> => {
    if (id) {
      await PhotoRequestService.unlockPhotoRequest(id);
    }

    addAlert({
      message: 'The request is unlocked for photographers',
      variant: 'success'
    });
  };

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Unlock Request">
        <LockOpenIcon />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Unlock"
        actionButton={{
          text: 'Unlock',
          method: async (): Promise<void> => {
            unlockRequest();
            setShowModal(false);
          }
        }}
      >
        <p>Are you sure you want to allow Photographer to edit the request?</p>
      </Modal>
    </Fragment>
  );
}
