import { NumberFormat } from '@homesusa/form';
import { MarketCode, MarketCodeLabel } from '@homesusa/core';

const requiredAndDisabledRule = (
  label: string
): { label: string; required: boolean; disabled: boolean } => ({
  label,
  required: false,
  disabled: true
});

const numberFormatRule = (label: string): { label: string; numberFormat: NumberFormat } => ({
  label,
  numberFormat: { prefix: '$', thousandSeparator: true }
});

export const photographerRules = {
  photographer: {
    firstName: requiredAndDisabledRule('First Name'),
    lastName: requiredAndDisabledRule('Last Name'),
    businessPhone: requiredAndDisabledRule('Bussiness Phone'),
    mobilePhone: requiredAndDisabledRule('Mobile Phone'),
    userName: requiredAndDisabledRule('Username'),
    sysCreatedOn: requiredAndDisabledRule('Created On')
  },
  market: {
    label: 'Markets',
    required: true,
    options: Object.values(MarketCode).reduce(
      (prevValue, currentValue) => ({
        ...prevValue,
        [currentValue]: MarketCodeLabel.get(currentValue)
      }),
      {}
    )
  },
  stillHome: numberFormatRule('Stills - Home'),
  stillExteriorOnly: numberFormatRule('Stills - Exterior Only'),
  twilight: numberFormatRule('3 Photos'),
  virtualTour: numberFormatRule('Virtual Tour'),
  virtualStagingOneImage: numberFormatRule('1 Image'),
  virtualStagingThreeImage: numberFormatRule('3 Images'),
  virtualStagingSixImage: numberFormatRule('6 Images'),
  communityOptionOne: numberFormatRule('Option 1'),
  communityOptionTwo: numberFormatRule('Option 2'),
  communityOptionThree: numberFormatRule('Option 3'),
  tripCharge: numberFormatRule('Trip Charge'),
  photoshopEditing: numberFormatRule('Photoshop Editing')
};
