import React from 'react';
import { Save } from '@mui/icons-material';

import { SubmitButton } from '@homesusa/layout';
import { useUpdatePhotoRequest } from '../../hooks';

export function SavePhotoRequest(): JSX.Element {
  const onSave = useUpdatePhotoRequest();

  return (
    <SubmitButton
      buttonProps={{
        className: 'primaryMain',
        startIcon: <Save />
      }}
      onClick={onSave}
    >
      Save
    </SubmitButton>
  );
}
