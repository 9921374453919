import React from 'react';

import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

export function CentralizedMessage({
  showModal,
  onCloseModal,
  photoManagerEmail,
  photoManagerName
}: {
  showModal: boolean;
  onCloseModal: () => void;
  photoManagerEmail: string;
  photoManagerName: string;
}): JSX.Element {
  return (
    <Dialog open={showModal} onClose={onCloseModal}>
      <DialogTitle>{`Create Photo Request - Centralized`}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography>To order photos, contact your company&apos;s photo manager.</Typography>
          <Typography component="div">
            <b>Name: </b> {photoManagerName}
          </Typography>
          <Typography component="div">
            <b>Email: </b> {photoManagerEmail}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
