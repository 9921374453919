import React, { Fragment } from 'react';
import { TextField } from '@mui/material';

import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { useReportError } from '../../hooks';
import { ErrorOutlineOutlined } from '@mui/icons-material';

export function ReportError(): JSX.Element {
  const reportError = useReportError();
  const [message, setmessage] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Report Error">
        <ErrorOutlineOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Report Photo Request Error"
        actionButton={{
          text: 'Send',
          method: async (): Promise<void> => {
            await reportError(message);
            setShowModal(false);
          }
        }}
      >
        <TextField
          fullWidth
          label="Enter Message:"
          multiline
          variant="outlined"
          name="message"
          rows={4}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setmessage(event.target.value);
          }}
        />
      </Modal>
    </Fragment>
  );
}
