import React, { Fragment } from 'react';
import { TextField, Button } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';

import { Modal } from './modal.component';
import { submitPhotoRequest } from 'core/services/photo-request.service';
import { PhotoRequestContext } from 'modules/photo-requests/contexts';

export function SubmitPhotoRequest(): JSX.Element {
  const [message, setmessage] = React.useState('');
  const { photoRequest, getPhotoRequest } = React.useContext(PhotoRequestContext);
  const [showModal, setShowModal] = React.useState(false);

  const onSubmit = async (): Promise<void> => {
    await submitPhotoRequest(photoRequest, message);
    await getPhotoRequest();
  };

  return (
    <Fragment>
      <Button
        className="primaryDark"
        startIcon={<SaveAlt />}
        onClick={(): void => setShowModal(true)}
      >
        Submit
      </Button>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Submit Photos"
        actionButton={{
          text: 'Submit',
          method: async (): Promise<void> => {
            await onSubmit();
            setShowModal(false);
          }
        }}
      >
        <TextField
          fullWidth
          label="Message to requestor (optional):"
          multiline
          variant="outlined"
          name="message"
          rows={4}
          sx={{
            mt: 1
          }}
          onChange={(event): void => {
            setmessage(event.target.value);
          }}
        />
      </Modal>
    </Fragment>
  );
}
