import { Stack, styled, SxProps } from '@mui/material';

interface Styles {
  table: SxProps;
  noDataContainer: SxProps;
  noDataIcon: SxProps;
  reportInformation: SxProps;
}

export const StackContainer = styled(Stack)(() => ({
  [`& button.MuiIconButton-root`]: {
    background: 'inherit'
  }
}));

export const useStyles = (): Styles => {
  return {
    table: {
      '& th': {
        '&.HusaGridTable-cell:last-child div': {
          justifyContent: 'flex-start'
        }
      },
      '& td': {
        maxWidth: '11ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    noDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      gap: '16px'
    },
    noDataIcon: { fontSize: '50px' },
    reportInformation: {
      display: 'inline-flex',
      gap: 2,
      padding: '8px 16px',
      marginBottom: 3,
      '& span': { fontWeight: 'bold' }
    }
  };
};
