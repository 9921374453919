import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';

import { SectionBox, useRefresh } from '@homesusa/core';
import { FormContext, Select } from '@homesusa/form';
import { PhotographerServices } from './photographer-services.component';
import { PhotographerContext, PhotographerFormProps } from '../contexts';

export const PhotographerServiceSection = (): JSX.Element => {
  const { photographerServices } = React.useContext(PhotographerContext);
  const { formDispatch } = React.useContext<PhotographerFormProps>(FormContext);
  const [hidePrices, refreshPrices] = useRefresh();

  return (
    <SectionBox>
      <Typography mb={2} variant="h3" color="initial">
        Photography Services
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <Select
            name="market"
            onChange={(value): void => {
              const payload =
                photographerServices.find((services) => services.market === value) ?? Object({});
              if (formDispatch) {
                formDispatch({
                  type: 'UpdateServicePrices',
                  payload
                });
                refreshPrices();
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {hidePrices ? <LinearProgress /> : <PhotographerServices />}
        </Grid>
      </Grid>
    </SectionBox>
  );
};
