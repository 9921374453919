import React from 'react';
import { isAfter } from 'date-fns';
import { Box, Grid, Typography } from '@mui/material';
import { Description } from '@mui/icons-material';

import { Format } from '@homesusa/core';

import { PhotographerService } from 'core/services';
import {
  PhotographerPayrollResponse,
  PhotographerReportRequest,
  PhotoRequestPayroll
} from 'core/interfaces';

import { PhotographerSelector } from '../photographer-selector/photographer-selector.component';
import { PayrollReportGrid } from './payroll-report-grid';
import { useStyles } from './photographer-report-grid.styles';
import { AuthCompanyContext } from '@homesusa/auth';
import { AppContext } from '@homesusa/layout';

const PriceView = ({ price, label }: { price: number; label: string }): JSX.Element => {
  return (
    <Grid item xs={3}>
      <Typography variant="body2">
        <span>{label}:</span> {Format.Money(price)}
      </Typography>
    </Grid>
  );
};

export const PhotographerPayrollReportGrid = (): JSX.Element => {
  const classes = useStyles();
  const { getPhotographersPayroll } = PhotographerService;
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const { addAlert } = React.useContext(AppContext);
  const [isFetching, setIsFetching] = React.useState(false);

  const [data, setData] = React.useState<PhotographerPayrollResponse>();
  const [reportRequest, setReportRequest] = React.useState<PhotographerReportRequest>(
    Object({ marketCode: currentMarket, isForDownloading: false })
  );

  const handleChange = (name: string, value: unknown): void => {
    setReportRequest((prev) => ({ ...prev, [name]: value }));
  };

  const fetchData = (): Promise<PhotographerPayrollResponse> =>
    getPhotographersPayroll({ ...reportRequest, isForDownloading: false });

  const fetchDataForDownloading = async (): Promise<PhotoRequestPayroll[]> => {
    const response = await getPhotographersPayroll({ ...reportRequest, isForDownloading: true });
    return response.data;
  };

  const handleClick = (): void => {
    const { startDate, endDate } = reportRequest;
    if (startDate && endDate && isAfter(startDate, endDate)) {
      addAlert({ variant: 'error', message: 'Start date cannot be after end date' });
    } else {
      setIsFetching(true);
      fetchData()
        .then((photoReport) => {
          setData(photoReport);
          setIsFetching(false);
        })
        .catch(() => setIsFetching(false));
    }
  };

  return (
    <Box maxWidth={'inherit'}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1">Photographer Payroll Report</Typography>
        <PhotographerSelector
          isFetching={isFetching}
          onChange={handleChange}
          onClick={handleClick}
          optionalFilters={{ company: true, market: true, type: true }}
          filters={reportRequest}
        />
      </Box>

      {!!data?.count ? (
        <Box>
          <Box sx={classes.tableContainer}>
            <PayrollReportGrid
              payrollSection={data.data}
              sx={classes.table}
              fetchDataForDownloading={fetchDataForDownloading}
            />
          </Box>
          <Typography variant="h2" mt={3} mb={1}>
            Payroll Totals
          </Typography>
          <Grid container sx={classes.reportInformation}>
            <PriceView price={data?.photoServicesTotal.stillImagesTotal} label="Still Images" />
            <PriceView price={data?.photoServicesTotal.virtualTourTotal} label="Virtual Tour" />
            <PriceView price={data?.photoServicesTotal.tripChargeTotal} label="Trip Charge" />
            <PriceView price={data?.photoServicesTotal.twilightTotal} label="Twilight (3 Images)" />
            <PriceView
              price={data?.photoServicesTotal.photoshopEditingTotal}
              label="Photoshop Editing"
            />
            <PriceView
              price={data?.photoServicesTotal.virtualStagingOnePhotoTotal}
              label="Virtual Staging 1 Photo"
            />
            <PriceView
              price={data?.photoServicesTotal.virtualStagingThreePhotoTotal}
              label="Virtual Staging 3 Photos"
            />
            <PriceView
              price={data?.photoServicesTotal.virtualStagingSixPhotoTotal}
              label="Virtual Staging 6 Photos"
            />
            <PriceView price={data?.photoServicesTotal.totalCost} label="Total" />
          </Grid>
        </Box>
      ) : (
        <Box sx={classes.noDataContainer}>
          <Description color="disabled" sx={classes.noDataIcon} />
          <Typography variant="h4" color="GrayText">
            There is no information to show
          </Typography>
        </Box>
      )}
    </Box>
  );
};
