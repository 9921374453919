import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '@homesusa/layout';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from '../contexts';

export const useDeleteRequest = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const { photoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const deleteRequest = async (): Promise<void> => {
    if (!photoRequest?.id) {
      return;
    }

    await PhotoRequestService.deletePhotoRequest(photoRequest.id);

    addAlert({
      message: 'The request was successfully deleted',
      variant: 'success'
    });
    navigate(`/photo-requests`);
  };

  return deleteRequest;
};
