import React from 'react';

import { Grid } from '@mui/material';

import { DatePicker, FormContext, FormMode, Input, PhoneInput } from '@homesusa/form';
import { useHasRole } from '@homesusa/auth';
import { RoleUser, SectionBox } from '@homesusa/core';
import { PhotoFormContextProps } from 'modules/photo-requests/contexts';

export function FormPhotographerTab(): JSX.Element {
  const {
    formState: { contactDate, scheduleDate },
    formDispatch
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const { isMlsAdministrator, hasUserRole } = useHasRole();
  const readOnly = React.useMemo(() => {
    return !isMlsAdministrator && !hasUserRole([RoleUser.Photographer]);
  }, [hasUserRole, isMlsAdministrator]);

  return (
    <SectionBox title="Photographer" sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input label="Full Name" name="photographerName" disabled />
        </Grid>
        <Grid item xs={6}>
          <Input label="Email" name="photographerEmail" disabled />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput label="Business Phone" name="photographerBusinessPhone" disabled />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput label="Mobile Phone" name="photographerMobilePhone" disabled />
        </Grid>
        <FormContext.Provider
          value={{
            formState: { contactDate, scheduleDate },
            formRules: {
              contactDate: {
                label: 'Contact Date',
                onChange: (value): void => {
                  if (formDispatch) {
                    formDispatch({
                      type: 'FormChangeByInputName',
                      inputName: 'contactDate',
                      payload: value
                    });
                  }
                }
              },
              scheduleDate: {
                label: 'Schedule Date',
                onChange: (value): void => {
                  if (formDispatch) {
                    formDispatch({
                      type: 'FormChangeByInputName',
                      inputName: 'scheduleDate',
                      payload: value
                    });
                  }
                }
              }
            },
            formMode: readOnly ? FormMode.ReadOnly : FormMode.Update,
            otherProps: {}
          }}
        >
          <Grid item xs={6}>
            <DatePicker name="contactDate" />
          </Grid>
          <Grid item xs={6}>
            <DatePicker name="scheduleDate" />
          </Grid>
        </FormContext.Provider>
      </Grid>
    </SectionBox>
  );
}
