import React from 'react';

import { MediaContext } from '@homesusa/media';
import { useHasRole } from '@homesusa/auth';
import { FormTabsOutlet, FormContext, FormMode, FormTabProps } from '@homesusa/form';
import { RoleEmployee, RoleUser, PhotoRequestStatus } from '@homesusa/core';
import { FormGeneralTab, FormPhotographerTab, PhotoMediaManager } from '../form-tabs';
import { PhotoFormTab, PhotoFormTabLabel } from '../../enums';
import { PhotoFormContextProps } from '../../contexts';
import { useNotesMenuButton } from 'modules/photo-requests/hooks';

type formTabsType = Record<string, FormTabProps>;

export function PhotoFormTabs(): JSX.Element {
  const {
    formState: { companyId, photographerId, status, isUnlocked },
    formReference,
    changeFormMode
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const { mediaStateDispatch } = React.useContext(MediaContext);
  const { hasUserRole, hasEmployeeRole } = useHasRole();
  const notesMenuButton = useNotesMenuButton();

  const getTabProps = (tab: PhotoFormTab): { id: string; label: string } => ({
    id: tab,
    label: PhotoFormTabLabel.get(tab) ?? tab
  });

  React.useEffect(() => {
    const isPhotographer = hasUserRole([RoleUser.Photographer]);
    const inReadOnly =
      [PhotoRequestStatus.PhotosApproved, PhotoRequestStatus.Cancelled].some(
        (photoStatus) => photoStatus == status
      ) || hasEmployeeRole([RoleEmployee.Readonly, RoleEmployee.CompanyAdminReadonly]);

    if (changeFormMode && (inReadOnly || isPhotographer)) {
      changeFormMode(FormMode.ReadOnly);
    }

    const availableMedia =
      isPhotographer && PhotoRequestStatus.PhotosApproved == status && isUnlocked;

    mediaStateDispatch({ type: 'ReadOnly', payload: inReadOnly && !availableMedia });
  }, [hasUserRole, hasEmployeeRole]);

  const tabs = React.useMemo(() => {
    const photographerTab = {
      [PhotoFormTab.Photographer]: {
        ...getTabProps(PhotoFormTab.Photographer),
        node: <FormPhotographerTab />
      }
    };

    const formTabs: formTabsType = {
      [PhotoFormTab.General]: {
        ...getTabProps(PhotoFormTab.General),
        node: <FormGeneralTab />
      },
      ...(photographerId != null ? photographerTab : {}),
      [PhotoFormTab.Media]: {
        ...getTabProps(PhotoFormTab.Media),
        node: <PhotoMediaManager />
      }
    };

    return formTabs;
  }, [photographerId]);

  const menu = React.useMemo(() => [notesMenuButton], [notesMenuButton]);

  return (
    <div ref={formReference}>
      {!!companyId && <FormTabsOutlet tabs={tabs} menuOptions={menu} />}
    </div>
  );
}
