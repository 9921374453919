import React, { Fragment } from 'react';
import { TextField } from '@mui/material';
import { ImageNotSupported } from '@mui/icons-material';

import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { usePhotosReject } from '../../hooks';

export function PhotosRejectedRequest(): JSX.Element {
  const [rejectedReason, setRejectedReason] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const rejectPhotos = usePhotosReject();

  return (
    <Fragment>
      <IconButton className="danger" onClick={(): void => setShowModal(true)} title="Reject Photos">
        <ImageNotSupported />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="What is the reason for rejecting the photos?"
        actionButton={{
          text: 'Reject Photos',
          method: async (): Promise<void> => {
            await rejectPhotos(rejectedReason);
            setShowModal(false);
          }
        }}
      >
        <TextField
          fullWidth
          label="Rejection reason"
          multiline
          variant="outlined"
          name="rejectedReason"
          rows={4}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setRejectedReason(event.target.value);
          }}
        />
      </Modal>
    </Fragment>
  );
}
