import { AppContext } from '@homesusa/layout';
import { SxProps } from '@mui/system';
import React from 'react';

interface Styles {
  tableContainer: SxProps;
  table: SxProps;
  noDataContainer: SxProps;
  noDataIcon: SxProps;
  reportInformation: SxProps;
}

export const useStyles = (): Styles => {
  const {
    appState: {
      layout: {
        sidebar: { openSubSidebar }
      }
    }
  } = React.useContext(AppContext);
  const sideBarWidth = openSubSidebar ? 330 : 137;

  return {
    tableContainer: {
      maxWidth: `calc(100vw - ${sideBarWidth}px )`,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#9a9b9b',
        borderRadius: '8px'
      }
    },
    table: {
      '& th': {
        '&.HusaGridTable-cell:last-child div': {
          justifyContent: 'flex-start'
        }
      },
      '& td': {
        maxWidth: '11ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    noDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      gap: '16px'
    },
    noDataIcon: { fontSize: '50px' },
    reportInformation: {
      padding: '8px 16px',
      flexWrap: 'wrap',
      marginBottom: 3,
      '& span': { fontWeight: 'bold' }
    }
  };
};
