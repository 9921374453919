import React from 'react';
import { Button, Grid, Paper } from '@mui/material';

import { DatePicker, FormStyled, Select } from '@homesusa/form';
import { PhotoRequestType } from '@homesusa/photo-request';
import { SelectCompany, SelectMarket, MultiSelectPhotoRequestType } from 'core/components';
import { PhotographerService } from 'core/services';
import { reportFilterStyles } from 'modules/photographers/styles';
import { PhotographerReportRequest } from 'core/interfaces';

const defaultTypes = Object.values(PhotoRequestType).filter(
  (value) => value !== PhotoRequestType.None
);

const aDayLess = (date?: Date): Date => {
  const yesterday = date ? new Date(date) : new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const PhotographerSelector = ({
  onChange,
  onClick,
  optionalFilters,
  filters,
  isFetching
}: {
  onChange: (name: string, value: unknown) => void;
  onClick: () => void;
  optionalFilters?: {
    market?: boolean;
    company?: boolean;
    type?: boolean;
  };
  filters: PhotographerReportRequest;
  isFetching?: boolean;
}): JSX.Element => {
  const classes = reportFilterStyles();
  const { getPhotographers } = PhotographerService;
  const [photographerOptions, setPhotographerOptions] = React.useState<Record<string, string>>({});
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  React.useEffect(() => {
    getPhotographers().then(({ data }) => {
      const photographerOptions = data.reduce((acc: Record<string, string>, photographer) => {
        acc[photographer.id] = photographer.firstName + ' ' + photographer.lastName;
        return acc;
      }, {});
      setPhotographerOptions(photographerOptions);
    });
  }, []);

  return (
    <Paper sx={classes.root}>
      <FormStyled sx={classes.form}>
        <Select
          options={photographerOptions}
          label="Photographer"
          size="small"
          onChange={(value): void => onChange('photographerId', value)}
        />
        <DatePicker
          label="Start Date"
          size="small"
          onChange={(value): void => onChange('startDate', value)}
          maxDate={aDayLess(filters.endDate)}
        />
        <DatePicker
          label="End Date"
          size="small"
          onChange={(value): void => onChange('endDate', value)}
          minDate={filters.startDate}
          maxDate={new Date()}
        />

        <Button
          variant="contained"
          sx={{ alignSelf: 'center' }}
          onClick={onClick}
          disabled={isFetching}
        >
          Search
        </Button>
      </FormStyled>

      {optionalFilters && (
        <Grid container spacing={1} mt={0.1}>
          {optionalFilters.company && (
            <Grid item xs={3.5}>
              <SelectCompany
                onSelect={(company): void => {
                  if (company) onChange('buildersIds', company.id);
                }}
                size="small"
              />
            </Grid>
          )}

          {optionalFilters.market && (
            <Grid item xs={2.5}>
              <SelectMarket
                size="small"
                onChange={({ target }): void => onChange('marketCode', target.value)}
              />
            </Grid>
          )}

          {optionalFilters.type && (
            <Grid item xs={6}>
              <MultiSelectPhotoRequestType
                label="Type"
                defaultValue={defaultTypes}
                onChange={(e): void => {
                  onChange('photoRequestType', e);
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
};
