import React, { Fragment } from 'react';
import { TextField } from '@mui/material';
import { PaymentOutlined } from '@mui/icons-material';

import { IconButton } from 'core/components';
import { TripChargeService } from 'core/services';
import { Modal } from './modal.component';
import { useTripChargeReason } from '../../hooks';

export function RequestTripCharge(): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  const { onSubmit, hasError, onChangeReason } = useTripChargeReason(
    'The Photo Request was successfully charged',
    TripChargeService.requestTripCharge
  );

  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Request Trip Charge">
        <PaymentOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Trip Charge"
        actionButton={{
          text: 'Submit',
          method: async (): Promise<void> => {
            const success = await onSubmit();
            if (success) {
              setShowModal(false);
            }
          }
        }}
      >
        <TextField
          fullWidth
          label="What is the reason this home is not photo-ready?"
          multiline
          variant="outlined"
          name="reason"
          rows={4}
          onChange={(event): void => onChangeReason(event.target.value)}
          helperText={hasError ? 'Enter a reason for not being photo-ready.' : undefined}
          error={hasError}
        />
      </Modal>
    </Fragment>
  );
}
