import React, { Fragment } from 'react';

import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import {
  Format,
  MarketCities,
  MarketCodeLabel,
  NeedingActionStatuses,
  PhotoRequestStatus,
  PhotoRequestStatusLabel,
  RoleEmployee,
  RoleUser
} from '@homesusa/core';
import { useDocumentTitle } from '@homesusa/layout';
import { AuthCompanyContext, useHasRole } from '@homesusa/auth';
import {
  GridTable,
  GridToolbar,
  GridProvider,
  GridResponse,
  FetchDataDownloading,
  useFetchData
} from '@homesusa/grid-table';
import { PhotoRequestTypeLabel, PhotoRequestType } from '@homesusa/photo-request';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestGrid as PhotoRequest, PhotoRequestGrid } from 'core/interfaces';
import { PhotoRequestCreateSteps } from '../components';

export function PhotoRequestsGrid(): JSX.Element {
  useDocumentTitle(`Photo Request`);
  const defaultSortBy = `+status,-sysModifiedOn`;
  const [photoRequestStatus, setPhotoRequestStatus] =
    React.useState<string[]>(NeedingActionStatuses);
  const [photoRequestType, setPhotoRequestType] = React.useState<PhotoRequestType>();
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const cities = React.useMemo(() => MarketCities.get(currentMarket) ?? {}, [currentMarket]);
  const { hasUserRole, hasEmployeeRole } = useHasRole();

  const canCreate = React.useMemo(() => {
    const photographer = hasUserRole([RoleUser.Photographer]);
    const readOnly = hasEmployeeRole([RoleEmployee.Readonly, RoleEmployee.CompanyAdminReadonly]);
    return !readOnly && !photographer;
  }, [hasUserRole, hasEmployeeRole]);

  const getPhotoRequest = ({ row }: { row: PhotoRequest }): void => {
    navigate(`/photo-requests/${row.id}`);
  };
  const columns: Array<Column<PhotoRequest>> = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: (data: PhotoRequest): string => PhotoRequestTypeLabel.get(data.type) ?? '',
        id: 'type'
      },
      {
        Header: 'Status',
        accessor: (data: PhotoRequest): string => PhotoRequestStatusLabel.get(data.status) ?? '',
        id: 'status'
      },
      {
        Header: 'Submit Date',
        accessor: (data: PhotoRequest): string => Format.DateTime(data.submitDate),
        id: 'sysCreatedOn'
      },
      {
        Header: 'Contact Date',
        accessor: (data: PhotoRequest): string => Format.Date(data.contactDate),
        id: 'contactDate'
      },
      {
        Header: 'Scheduled Date',
        accessor: (data: PhotoRequest): string => Format.Date(data.scheduleDate),
        id: 'scheduleDate'
      },
      {
        Header: 'Modified on',
        accessor: (data: PhotoRequest): string => Format.DateTime(data.modifiedOn),
        id: 'sysModifiedOn'
      },
      {
        Header: 'Modified By',
        accessor: (data: PhotoRequest): string => data.modifiedByName ?? '',
        id: 'sysModifiedBy'
      },
      {
        Header: 'Market',
        accessor: (): string => MarketCodeLabel.get(currentMarket) ?? '',
        id: 'market'
      },
      {
        Header: 'Builder',
        accessor: 'companyName'
      },
      {
        Header: 'Subdivision',
        accessor: 'subdivision'
      },
      {
        Header: 'Address',
        accessor: 'address',
        id: 'modelAddress'
      },
      {
        Header: 'Unit Number',
        accessor: 'unitNumber'
      },
      {
        Header: 'City',
        accessor: (data: PhotoRequest): string => cities[data.city] ?? '',
        id: 'city'
      },
      {
        Header: 'Assigned to',
        accessor: 'photographerName',
        id: 'photographerId'
      },
      {
        Header: 'Submitted By',
        accessor: 'submittedByName',
        id: 'SysCreatedBy'
      }
    ],
    [cities]
  );

  const getGridData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      sortBy,
      isForDownloading
    }: FetchDataDownloading): Promise<GridResponse<PhotoRequestGrid>> => {
      return PhotoRequestService.getActivePhotoRequests({
        pageSize,
        startIndex: pageIndex,
        status: globalFilter != null ? undefined : photoRequestStatus,
        type: photoRequestType,
        searchBy: globalFilter,
        sortBy: sortBy ?? defaultSortBy,
        isForDownloading
      });
    },
    [photoRequestStatus, photoRequestType]
  );

  const { fetchData, fetchDataForDownloading, data } = useFetchData<PhotoRequestGrid>(getGridData);

  return (
    <Fragment>
      <GridProvider
        options={{ columns, data: data?.data }}
        totalRows={data?.total}
        fetchData={fetchData}
      >
        <GridToolbar>
          {canCreate && <GridToolbar.AddButton onClick={(): void => setShowAddModal(true)} />}
          <GridToolbar.ExportButtons onClick={fetchDataForDownloading} fileName="photo-requests" />
          <GridToolbar.SearchFilter />
          <GridToolbar.SelectFilter
            defaultValue={photoRequestType ?? ''}
            onChange={(value): void => {
              setPhotoRequestType(value as PhotoRequestType);
            }}
            options={Object.values(PhotoRequestType).reduce(
              (a, x) => ({ ...a, [x.toString()]: PhotoRequestTypeLabel.get(x) }),
              { '': 'Select type...' }
            )}
            label="Type"
          />
          <GridToolbar.MultiSelectFilter
            defaultValue={photoRequestStatus}
            onChange={(value): void => setPhotoRequestStatus(value)}
            options={Object.values(PhotoRequestStatus).reduce(
              (a, x) => ({ ...a, [x.toString()]: PhotoRequestStatusLabel.get(x) }),
              {}
            )}
            label="Status"
          />
        </GridToolbar>
        <GridTable onRowClick={getPhotoRequest} />
      </GridProvider>
      <PhotoRequestCreateSteps
        openModal={showAddModal}
        setOpenModal={(value): void => setShowAddModal(value)}
      />
    </Fragment>
  );
}
