import React from 'react';

import { AppContext } from '@homesusa/layout';
import { PhotoRequestService } from 'core/services';
import { PhotoRequestContext } from '../contexts';

export const usePhotosReject = (): ((rejectedReason: string) => Promise<void>) => {
  const { photoRequest, getPhotoRequest } = React.useContext(PhotoRequestContext);
  const { addAlert } = React.useContext(AppContext);

  const photosRejected = async (rejectedReason: string): Promise<void> => {
    if (!photoRequest || rejectedReason === '') {
      return;
    }

    await PhotoRequestService.photosRejectRequest(photoRequest, rejectedReason).then(() => {
      getPhotoRequest();
    });

    addAlert({
      message: 'The photos were rejected',
      variant: 'success'
    });
  };

  return photosRejected;
};
