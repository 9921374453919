import React from 'react';
import { Paper } from '@mui/material';

import { DatePicker, FormStyled } from '@homesusa/form';
import { SubmitButton } from '@homesusa/layout';
import { reportFilterStyles } from 'modules/photographers/styles';

export const ReportFilter = ({
  onChange,
  onClick
}: {
  onChange: (name: string, value: unknown) => void;
  onClick: () => Promise<void>;
}): JSX.Element => {
  const classes = reportFilterStyles();
  return (
    <Paper sx={classes.root}>
      <FormStyled sx={classes.form}>
        <DatePicker
          label="Start Date"
          size="small"
          onChange={(value): void => onChange('startDate', value)}
        />
        <DatePicker
          label="End Date"
          size="small"
          onChange={(value): void => onChange('endDate', value)}
        />

        <SubmitButton
          buttonProps={{
            variant: 'contained',
            sx: { alignSelf: 'center' }
          }}
          onClick={onClick}
        >
          Search
        </SubmitButton>
      </FormStyled>
    </Paper>
  );
};
