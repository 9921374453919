import React, { Fragment } from 'react';
import { TextField } from '@mui/material';
import { BlockOutlined } from '@mui/icons-material';

import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { useCancelRequest } from '../../hooks';

export function CancelPhotoRequest(): JSX.Element {
  const [cancelledReason, setCancelledReason] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const cancelRequest = useCancelRequest();
  const [hasError, setHasError] = React.useState(false);

  return (
    <Fragment>
      <IconButton
        className="danger"
        onClick={(): void => setShowModal(true)}
        title="Cancel Request"
      >
        <BlockOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="What is the reason you wish to cancel this photo request?"
        actionButton={{
          text: 'Cancel',
          method: async (): Promise<void> => {
            if (cancelledReason == null || cancelledReason === '') {
              setHasError(true);
            } else {
              await cancelRequest(cancelledReason);
              setShowModal(false);
            }
          }
        }}
      >
        <TextField
          fullWidth
          label="Cancellation reason"
          multiline
          variant="outlined"
          defaultValue={cancelledReason}
          rows={4}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            if (event.target.value !== '') {
              setHasError(false);
            }
            setCancelledReason(event.target.value);
          }}
          helperText={hasError ? 'Enter a reason for cancellation.' : undefined}
          error={hasError}
        />
      </Modal>
    </Fragment>
  );
}
